// If you don't want to use TypeScript you can delete this file!
import * as React from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-awesome-styled-grid"
import Input from "../Commons/Input/index"
import Button from "../Commons/Button/index"
import Title from "../Commons/Title"
import DatePicker from "react-datepicker"

import { Formik, Form, Field, useField, useFormikContext } from "formik"
import * as Yup from "yup"

const Schema = Yup.object().shape({
  EMAIL: Yup.string()
    .email("Invalid e-mail address!")
    .required("Required field!"),
  FNAME: Yup.string().required("Required field!"),
  acceptTerms: Yup.boolean()
    .required("Required field!")
    .oneOf([true], "Required field!"),
})

const CustomInput = React.forwardRef((props, ref) => (
  <Input {...props} ref={ref} />
))

const DatePickerField = ({ ...props }) => {
  const { setFieldValue } = useFormikContext()
  const [field] = useField(props)
  const ref = React.useRef(null)
  return (
    <DatePicker
      {...field}
      {...props}
      closeOnScroll={true}
      placeholderText="Birthday"
      customInput={<CustomInput error={props.error} ref={ref} />}
      selected={(field.value && new Date(field.value)) || null}
      onChange={val => {
        setFieldValue(field.name, val)
      }}
    />
  )
}

const Section = styled.section`
  background-color: ${props => props.theme.variables.secondary.blue};

  padding-top: 76px;
  padding-bottom: 100px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding-top: 67px;
    padding-bottom: 150px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding-top: 105px;
    padding-bottom: 105px;
  }
`

const Content = styled.section``

const ButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 24px;
  margin-left: 0px;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    display: block;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: 40px;
  }
`

const FormWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle,
  .react-datepicker-popper[data-placement^="bottom"]
    .react-datepicker__triangle {
    margin-left: -20px;
  }

  input {
    margin-bottom: 24px;
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 32px;
    input {
      margin-bottom: 40px;
    }
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: 40px;
    input {
      margin-right: 24px;
    }
  }
`

type SWITCH_PROPS = {
  error: boolean
}

const SwitchRow = styled.div<SWITCH_PROPS>`
  margin-top: 48px;
  p {
    color: ${props => (props.error ? "#EF4444" : " #d8d1c8")};
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 32px;
  }
`

const CheckBoxWrapper = styled.div`
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    flex-direction: row;
  }
`
const CheckBoxLabel = styled.label`
  position: absolute;
  top: 0px;
  left: 0;
  width: 40px;
  height: 20px;
  border-radius: 10px;
  background: #111111;
  cursor: pointer;
  &::after {
    content: "";
    display: block;
    border-radius: 50%;
    width: 14px;
    height: 14px;
    margin: 3px;
    background: #fff;
    transition: 0.2s;
  }
`
const CheckBox = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 10px;
  width: 40px;
  height: 20px;
  margin: 0;
  &:checked + ${CheckBoxLabel} {
    background: #111111;
    &::after {
      margin-left: 22px;
      background: #fbda03;
    }
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-right: 24px;
  }
`

const SpanBox = styled.span`
  position: relative;
`

const Label = styled.label`
  cursor: pointer;
  text-align: center;
  max-width: 471px;
  font-family: "Roboto", sans-serif;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  letter-spacing: -0.01em;
  color: #ffffff;
  margin-top: 16px;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    text-align: left;
    margin-top: 0px;
  }
`

const SuccessMessage = styled.div`
  margin-top: 64px;
`

const SuccessTitle = styled.h4`
  min-height: 250px;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 8px;
`
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-bottom: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 64px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-bottom: 0;
    display: block;
  }
`
const Image = styled.img`
  max-width: 480px;
  width: 100%;
  height: auto;
  object-fit: cover;
  cursor: grabbing;

  &:hover {
    animation: jelly-mobile 0.5s ease-in-out 1 alternate;
    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
      animation: jelly-desktop 0.5s ease-in-out 1 alternate;
    }
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    max-width: none;
    position: absolute;
    width: 480px;
    right: 32px;
    top: 50%;
    transform: translateY(-50%);
  }

  @media (min-width: 1640px) {
    right: 68px;
    width: 597px;
  }

  @keyframes jelly-mobile {
    25% {
      transform: scale(0.95, 1.1);
    }

    50% {
      transform: scale(1.1, 0.9);
    }

    75% {
      transform: scale(0.95, 1.05);
    }
  }

  @keyframes jelly-desktop {
    25% {
      transform: translateY(-50%) scale(0.95, 1.1);
    }

    50% {
      transform: translateY(-50%) scale(1.1, 0.9);
    }

    75% {
      transform: translateY(-50%) scale(0.95, 1.05);
    }
  }
`

const TitleWrapper = styled.div`
  @media (max-width: ${props => props.theme.awesomegrid.breakpoints.lg - 0.01}rem) {
    text-align: center;
  }
`

type Props = {
  title: string
  image: string
}

const Newsletter: React.FC<Props> = ({ title, image }) => {
  const [isSuccess, setIsSuccess] = React.useState(false)

  return (
    <Section id="newsletter" >
      <Container>
        <Row>
          <Col xs={12} lg={5}>
            <ImageWrapper>
              <Image src={image} draggable="false" />
            </ImageWrapper>
          </Col>
          <Col xs={12} lg={7}>
            <Content>
              <TitleWrapper>
                <Title level={1} variable="primary" color="white" uppercase>
                  {title}
                </Title>
              </TitleWrapper>
              {isSuccess ? (
                <SuccessMessage>
                  <SuccessTitle>Welcome to the Choice Provisions mailing list! Stay in the loop about all our upcoming projects, convention appearances, and general antics!</SuccessTitle>
                </SuccessMessage>
              ) : (
                <>
                  <Formik
                    initialValues={{
                      EMAIL: "",
                      FNAME: "",
                      acceptTerms: false,
                    }}
                    validationSchema={Schema}
                  // onSubmit={async (values, { resetForm }) => {
                  //   console.table([
                  //     values?.email,
                  //     values?.birthday,
                  //     values?.acceptTerms,
                  //   ])
                  //   resetForm()
                  //   setIsSuccess(true)
                  // }}
                  >
                    {({ errors, touched, setFieldTouched, resetForm, isValid, dirty }) => (
                      <form onSubmit={() => {
                        setTimeout(() => {
                          setIsSuccess(true)
                        }, 0);
                      }} action="https://totallychoice.us4.list-manage.com/subscribe/post?u=d0dea30bfb1f9b7aadc48ac68&amp;id=f53dee10a6" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank" noValidate>
                        <FormWrapper>
                          <Field
                            name="EMAIL"
                            type="text"
                            render={({ field }) => (
                              <Input
                                error={
                                  errors.EMAIL && touched.EMAIL ? true : false
                                }
                                autocomplete="off"
                                placeholder="Email"
                                {...field}
                              />
                            )}
                          />

                          <Field
                            name="FNAME"
                            type="text"
                            render={({ field }) => (
                              <Input
                                error={
                                  errors.FNAME && touched.FNAME ? true : false
                                }
                                autocomplete="off"
                                placeholder="Name"
                                {...field}
                              />
                            )}
                          />

                          <ButtonWrapper>
                            <Button
                              type="primary"
                              disabled={!(isValid && dirty)}
                              button_type="submit"
                            >
                              subscribe
                            </Button>
                          </ButtonWrapper>
                        </FormWrapper>

                        <Field
                          name="acceptTerms"
                          type="checkbox"
                          render={({ field }) => (
                            <SwitchRow
                              error={
                                errors.acceptTerms && touched.acceptTerms
                                  ? true
                                  : false
                              }
                              onClick={() => setFieldTouched("acceptTerms")}
                            >
                              <CheckBoxWrapper>
                                <SpanBox>
                                  <CheckBox
                                    id="checkbox"
                                    type="checkbox"
                                    {...field}
                                  />
                                  <CheckBoxLabel htmlFor="checkbox" />
                                </SpanBox>
                                <Label htmlFor="checkbox">
                                  By submitting this form we agree to receive emails from Choice Provisions.
                                </Label>
                              </CheckBoxWrapper>
                            </SwitchRow>
                          )}
                        />
                      </form>
                    )}
                  </Formik>
                </>
              )}
            </Content>
          </Col>
        </Row>
      </Container>
    </Section>
  )
}

export default Newsletter
