import React, { useState, useRef, ReactInstance } from "react"
import styled from "styled-components"
import PlayButtonIcon from "../../images/Hero/play-icon.svg"

const VideoWrapper = styled.div<any>`
  position: relative;
  cursor:  ${props => props.src ? "pointer" : "inherit"};
  overflow: hidden;
  border: 1px solid #ffa632;
  box-sizing: content-box;
  border-radius: 24px;

  video {
    width: 100%;
    // border: 1px solid #ffa632;
    // box-sizing: content-box;
    // border-radius: 24px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    max-width: 80%;
    margin: auto;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    max-width: 100%;
    margin: 0;
  }
`

const BannerWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  transform: translate(-50%, -50%);
  display: block;
`

const PlayButton = styled.div`
  position: relative;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  background-color: ${props => props.theme.variables.secondary.yellow};
  margin: auto;

  transition: all 1200ms cubic-bezier(0.19, 1, 0.22, 1);

  &:hover {
    transform: scale(1.1);
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
    width: 88px;
    height: 88px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    width: 144px;
    height: 144px;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -50%);
    width: 24px;
    height: 27px;

    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
      width: 32px;
      height: 26px;
    }

    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
      width: 52px;
      height: 42px;
    }
  }
`

const VideoSubTitle = styled.p`
  text-transform: capitalize;
  color: ${props => props.theme.variables.primary.white};
  text-align: center;
  letter-spacing: 0.15em;
  font-weight: bold;
  font-size: 10px;
  line-height: 6px;
  margin-top: 7.71px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    font-size: 12px;
    line-height: 16px;
    margin-top: 12.52px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: 16px;
  }
`

const ImagePoster = styled.img`
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
`

const StyledA = styled.a`
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
`

type VideoType = {
  src: string
  VideoImagePoster: string
  autoPlay?: boolean
}

const Video = (props: VideoType) => {
  const { src, autoPlay, VideoImagePoster } = props

  const [isOpen, setIsOpen] = useState(true)
  const [imagePoster, setImagePoster] = useState(true)
  const vidRef = useRef<HTMLVideoElement>(null)

  const togglePlayVideo = () => {
    return;

    if (!autoPlay) {
      setIsOpen(!isOpen)

      if (vidRef?.current?.paused) {
        vidRef?.current?.play()
        setImagePoster(false)
      } else {
        vidRef?.current?.pause()
      }
    }
  }

  return (
    <>
      <StyledA href={src} target="_blank">
        <VideoWrapper src={src} onClick={togglePlayVideo}>
          <video aria-disabled autoPlay={autoPlay} muted loop ref={vidRef} src={src}></video>
          {!autoPlay && (
            <>
              {imagePoster && (
                <ImagePoster
                  className="noselect"
                  src={VideoImagePoster}
                ></ImagePoster>
              )}
            </>
          )}
          {!autoPlay && src && (
            <>
              {isOpen && (
                <BannerWrapper>
                  <PlayButton>
                    <PlayButtonIcon />
                  </PlayButton>
                  <VideoSubTitle>watch trailer</VideoSubTitle>
                </BannerWrapper>
              )}
            </>
          )}
        </VideoWrapper>
      </StyledA>

    </>
  )
}

export default Video
