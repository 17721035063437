import React, { Children } from "react"
import styled from "styled-components"
// import PropTypes from 'prop-types';

type INPUT_PROPS = {
  error: boolean
}

const InputBase = styled.input<INPUT_PROPS>`
  border: 1px solid ${props => (props.error ? "#EF4444" : "#D2D6DA")};
  border-radius: 8px;
  outline: none;
  padding: 16px 24px;
  background: #f1ede5;

  &,
  &::placeholder {
    font-family: "Montserrat", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 130%;
    letter-spacing: 0.03em;
    color: #495057;
  }
`

function Input(props: INPUT_PROPS) {
  return <InputBase {...props} />
}

export default Input
