// If you don't want to use TypeScript you can delete this file!
import React, { useEffect } from "react"
import { PageProps, graphql } from "gatsby"
import { Link, Trans, useTranslation } from "gatsby-plugin-react-i18next"
import GamesModule from "../components/GamesModule"

import Newsletter from "../components/NewsletterModule"
import Layout from "../components/layout"
import Seo from "../components/seo"
import AboutUs from "../components/AboutUs"

import Hero from "../components/Hero"
import HeroBgMobile from "../images/Hero/hero-sm.png"
import HeroBgTablet from "../images/Hero/hero-md.png"
import HeroBgDesktop from "../images/Hero/hero-xl.png"

import RERUNNERBG from "../images/Hero/RERUNNER_BG.jpg"
import RERUNNERTH from "../images/Hero/RERUNNER_thumb.jpg"
import JFGBG from "../images/Hero/JFG_BG.png"
import JFGTH from "../images/Hero/JFG_thumb.png"
import TharsisBG from "../images/Hero/Tharsis_BG.png"
import TharsisTH from "../images/Hero/THARSIS_Thumb.png"

import VideoPoster from "../images/Hero/video-poster.png"

import GamesImage1 from "../images/GamesCards/gamescard-1.png"
import GamesImage2 from "../images/GamesCards/gamescard-2.png"
import GamesImage3 from "../images/GamesCards/gamescard-3.png"
import GamesImage4 from "../images/GamesCards/gamescard-4.png"

import CARDOFDARKNESS from "../images/GamesCards/CARDOFDARKNESS.png"
import RUNNER2 from "../images/GamesCards/RUNNER2.png"
import RUNNER3 from "../images/GamesCards/RUNNER3.png"
import THARSIS2 from "../images/GamesCards/THARSIS 2.png"

import newsletterImage from "../images/Newsletter/newsletter.png"
import newsletterImageRunner from "../images/Newsletter/newsletter-runner.png"
import AboutUsImage from "../images/AboutUs/about-us-right.png"

const IndexPage: React.FC<PageProps> = () => {
  const { t } = useTranslation()

  useEffect(() => {
    if (window !== undefined) {
      require("lazysizes/plugins/attrchange/ls.attrchange.js")
      require("lazysizes/plugins/bgset/ls.bgset")
      require("lazysizes")
    }
  }, [])

  return (
    <Layout>
      <Seo
        title="Choice Provisions | Home"
        description="Choice Provisions was founded in 2007, making us about 417 in game industry years. We've taken the wisdom we've accrued over time and focused it towards developing the best games we can in the most sustainable way possible."
      />
      <Hero
        items={[
          {
            mobileBG: RERUNNERBG,
            tabletBG: RERUNNERBG,
            laptopBG: RERUNNERBG,
            desktopBG: RERUNNERBG,
            videoPoster: RERUNNERTH,
            videoUrl: "https://www.youtube.com/watch?v=AsLm6H1W1m4",
            status: "BIT.TRIP RERUNNER",
            platformTitle: "Availble On",
            platforms: ["microsoft"],
            subTitle:
              "Unleash your creativity with the RUNNER-MAKER tool, create and share your own levels.",
            firstButtonText: "Buy Now",
            cta_text: "Buy Now",
            cta_link:
              "https://store.steampowered.com/app/2222540/BITTRIP_RERUNNER/",
            // secondButtonText: "Join Discord",
            // second_cta_link: "https://discord.gg/EqeY2qf8zX",
            secondButtonText: "Visit Site",
            second_cta_link: "https://www.rerunner.game",
          },
          {
            mobileBG: JFGBG,
            tabletBG: JFGBG,
            laptopBG: JFGBG,
            desktopBG: JFGBG,
            videoPoster: JFGTH,
            videoUrl: "",
            status: "Hextech Mayhem: A League of Legends Story",
            platformTitle: "Available On",
            platforms: ["switch", "microsoft", "apple", "android"],
            subTitle:
              "League of Legends' Ziggs brings his unique brand of chaos to the once-peaceful city of Piltover!",
            cta_text: "VISIT SITE",
            cta_link: "https://hextechmayhem.com/purchase/",
            second_cta_link: "https://hextechmayhem.com/",
            firstButtonText: "buy now",
            secondButtonText: "visit site",
          },
          {
            mobileBG: TharsisBG,
            tabletBG: TharsisBG,
            laptopBG: TharsisBG,
            desktopBG: TharsisBG,
            videoPoster: TharsisTH,
            videoUrl: "https://www.youtube.com/watch?v=LvFNn4PZa4A",
            status: "Tharsis",
            platformTitle: "Available On",
            platforms: ["microsoft", "ps4", "apple"],
            subTitle:
              "Tharsis is a turn-based space strategy game. With dice. And cannibalism. It puts you in control of humanity’s first mission to Mars, just as it’s struck by a micrometeoroid storm.",
            cta_text: "VISIT SITE",
            cta_link: "https://store.steampowered.com/app/323060/Tharsis/",
            second_cta_link: "https://www.tharsis.space/",
            firstButtonText: "buy now",
            secondButtonText: "visit site",
          },
          {
            mobileBG: HeroBgMobile,
            tabletBG: HeroBgTablet,
            laptopBG: HeroBgDesktop,
            desktopBG: HeroBgDesktop,
            videoPoster: VideoPoster,
            videoUrl: "https://www.youtube.com/watch?v=5ES2KNejcbI",
            status: "Runner3",
            platformTitle: "Available Now",
            platforms: ["switch", "microsoft"],
            subTitle:
              "The rhythm-music gameplay of BIT.TRIP RUNNER and Runner2 is back and better than ever!",
            cta_text: "VISIT SITE",
            cta_link: "https://www.nintendo.com/games/detail/runner3-switch/",
            second_cta_link:
              "https://store.playstation.com/en-us/product/UP2148-CUSA12098_00-RUNNER3000000000",
            firstButtonText: "buy now",
            secondButtonText: "visit site",
          },
        ]}
      />
      <GamesModule
        title="Games"
        button_text="View ALL"
        padding_top={{ sm: 36, md: 0, lg: 0 }}
        padding_bottom={{ sm: 56, md: 108, lg: 108 }}
        right_image={true}
        cards={[
          {
            col: 6,
            CardsData: {
              trailer: "https://www.youtube.com/watch?v=AsLm6H1W1m4",
              image: RERUNNERTH,
              title: "BIT.TRIP RERUNNER",
              description:
                "Unleash your creativity with the RUNNER-MAKER tool, create and share your own levels.",
              avaliable_platforms: [
                {
                  icon: "Steam",
                  size: "48",
                  link: "https://store.steampowered.com/app/2222540/BITTRIP_RERUNNER/",
                },
              ],
            },
          },
          {
            col: 6,
            CardsData: {
              trailer: "",
              image: JFGTH,
              title: "Hextech Mayhem: A League of Legends Story",
              description:
                "League of Legends' Ziggs brings his unique brand of chaos to the once-peaceful city of Piltover!",
              avaliable_platforms: [
                {
                  icon: "Nintento-Switch",
                  size: "48",
                  link: "https://www.nintendo.com/games/detail/hextech-mayhem-a-league-of-legends-story-switch/",
                },
                {
                  icon: "epic-games",
                  size: "40",
                  link: "https://www.epicgames.com/store/p/hextech-mayhem-a-league-of-legends-story",
                },
                {
                  icon: "microsoft",
                  size: "40",
                  link: "https://store.steampowered.com/app/1651960/Hextech_Mayhem_A_League_of_Legends_Story/",
                },
                {
                  icon: "Gog",
                  size: "48",
                  link: "https://www.gog.com/game/hextech_mayhem_a_league_of_legends_story",
                },
              ],
            },
          },
          {
            col: 6,
            CardsData: {
              trailer: "https://www.youtube.com/watch?v=LvFNn4PZa4A",
              image: THARSIS2,
              title: "Tharsis",
              description:
                "Tharsis is a turn-based space strategy game. With dice. And cannibalism. It puts you in control of humanity’s first mission to Mars, just as it’s struck by a micrometeoroid storm.",
              avaliable_platforms: [
                {
                  icon: "microsoft",
                  size: "48",
                  link: "https://store.steampowered.com/app/323060/Tharsis/",
                },
                {
                  icon: "epic-games",
                  size: "48",
                  link: "https://www.epicgames.com/store/en-US/p/tharsis",
                },
                {
                  icon: "ps4",
                  size: "48",
                  link: "https://store.playstation.com/en-us/product/UP2148-CUSA02096_00-THARSIS000000000/",
                },
                {
                  icon: "Apple",
                  size: "48",
                  link: "https://apps.apple.com/us/app/tharsis/id956621095",
                },
                {
                  icon: "Nintento-Switch",
                  size: "48",
                  link: "https://www.nintendo.com/games/detail/tharsis-switch/",
                },
              ],
            },
          },
          {
            col: 6,
            CardsData: {
              trailer: "https://www.youtube.com/watch?v=5ES2KNejcbI&amp;t=3s",
              image: RUNNER3,
              title: "Runner3",
              description:
                "The rhythm-music gameplay of BIT.TRIP RUNNER and Runner2 is back and better than ever!",
              avaliable_platforms: [
                {
                  icon: "Nintento-Switch",
                  size: "48",
                  link: "https://www.nintendo.com/games/detail/runner3-switch/",
                },
                {
                  icon: "ps4",
                  size: "48",
                  link: "https://store.playstation.com/en-us/product/UP2148-CUSA12098_00-RUNNER3000000000/",
                },
                {
                  icon: "microsoft",
                  size: "48",
                  link: "https://store.steampowered.com/app/516130/Runner3/",
                },
              ],
            },
          },
          {
            col: 6,
            CardsData: {
              trailer: "https://www.youtube.com/watch?v=rymHRU6HGgs",
              image: CARDOFDARKNESS,
              title: "Card of Darkness",
              description:
                "Embark on an epic hand-animated adventure from Zach Gage, Pendleton Ward, and Choice Provisions.\n\nCast powerful spells, slay fantastic monsters, discover ancient secrets, and ultimately save the world — just by picking up the right cards.\n\nCard of Darkness is a full-featured adventure designed around an accessible, minimalist, card game core.",
              avaliable_platforms: [
                {
                  icon: "apple-arcade",
                  size: "48",
                  link: "https://apps.apple.com/us/app/card-of-darkness/id1364257627#?platform=iphone",
                },
              ],
            },
          },
          {
            col: 6,
            CardsData: {
              trailer: "https://www.youtube.com/watch?v=zbia07Cq6_w",
              image: GamesImage1,
              title: "The BIT.TRIP series",
              description: "The series that started it all.",
              avaliable_platforms: [
                {
                  icon: "ps4",
                  size: "48",
                  link: "https://store.playstation.com/en-us/product/UP2148-CUSA02752_00-THEBITTRIP000000/",
                },
                {
                  icon: "Nintento-Switch",
                  size: "48",
                  link: "https://www.nintendo.com/games/game-guide/#filter/:q=bit.trip",
                },
              ],
            },
          },
          // {
          //   col: 6,
          //   CardsData: {
          //     trailer: "https://www.youtube.com/watch?v=1HI60A2oNmo",
          //     image: RUNNER2,
          //     title: "Runner2",
          //     description:
          //       "Runner2 is the sequel to the critically acclaimed and IGF award winning BIT.TRIP RUNNER.",
          //     avaliable_platforms: [
          //       {
          //         icon: "microsoft",
          //         size: "48",
          //         link: "https://store.steampowered.com/app/218060/BITTRIP_Presents_Runner2_Future_Legend_of_Rhythm_Alien/",
          //       },
          //       {
          //         icon: "ps4",
          //         size: "48",
          //         link: "https://store.playstation.com/en-us/product/UP4457-CUSA04816_00-RUNNER2000000000/",
          //       },
          //       {
          //         icon: "Xbox",
          //         size: "48",
          //         link: "https://www.microsoft.com/en-us/p/Runner2/BPJC0C7GNBFB?activetab=pivot:overviewtab",
          //       },
          //     ],
          //   },
          // },
        ]}
      />
      <AboutUs
        image={AboutUsImage}
        runner_image={newsletterImageRunner}
        title="About Us"
        description="Choice Provisions was founded in 2007, making us about 417 in game industry years. We've taken the wisdom we've accrued over time and focused it towards developing the best games we can in the most sustainable way possible."
        button_text="Learn more"
      />
      <Newsletter title="Newsletter" image={newsletterImage} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
