import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { Container, Row, Col } from "react-awesome-styled-grid"
import Video from "../Video"

import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, {
  Thumbs,
  Navigation,
  Pagination,
  EffectFade,
  Controller,
  Autoplay,
  SwiperOptions,
} from "swiper/core"
import SwiperClass from "swiper/types/swiper-class"

import Title from "../Commons/Title"
import Paragraph from "../Commons/Paragraph"
import Button from "../Commons/Button"
import Icons from "../Icons"

import Runner from "../../images/Hero/runner.svg"
import Runbow from "../../images/Hero/runbow.svg"

// import "lazysizes"
// import "lazysizes/plugins/attrchange/ls.attrchange"
// import "lazysizes/plugins/bgset/ls.bgset"

SwiperCore.use([
  Thumbs,
  Navigation,
  Pagination,
  EffectFade,
  Controller,
  Autoplay,
])

const platformIconList: any = {
  android: <Icons icon="Android" size={22} color="#fff" />,
  apple: <Icons icon="Apple" size={22} color="#fff" />,
  epic: <Icons icon="epic-games" size={22} color="#fff" />,
  gogcom: <Icons icon="Gog" size={22} color="#fff" />,
  ps: <Icons icon="Playstation" size={22} color="#fff" />,
  steam: <Icons icon="Steam" size={22} color="#fff" />,
  switch: <Icons icon="Nintento-Switch" size={22} color="#fff" />,
  xbox: <Icons icon="Xbox" size={22} color="#fff" />,
  microsoft: <Icons icon="microsoft" size={22} color="#fff" />,
  ds: <Icons icon="ds" size={22} color="#fff" />,
  vita: <Icons icon="vita" size={22} color="#fff" />,
  "wii-u": <Icons icon="wii-u" size={22} color="#fff" />,
  ps4: <Icons icon="ps4" size={22} color="#fff" />,
  ps3: <Icons icon="ps3" size={22} color="#fff" />,
  browser: <Icons icon="browser" size={22} color="#fff" />,
  "wii-ware": <Icons icon="wii-ware" size={22} color="#fff" />,
  wii: <Icons icon="wii" size={22} color="#fff" />,
}

const ButtonWrapper = styled.div`
  margin-top: 16px;
  margin-bottom: 56px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  button {
    margin-bottom: 16px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    flex-direction: row;
    justify-content: center;
    button {
      margin-right: 24px;
    }
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    justify-content: flex-start;
  }
`
const HeadingWrapper = styled.div`
  margin-bottom: 16px;
  margin-top: 16px;
  h3 {
    text-align: center;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 4.083vw;
    margin-bottom: 0.938vw;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: 2.083vw;
    h3 {
      text-align: left;
    }
    width: 100%;
  }
`

const Section = styled.section`
  position: relative;
  padding: 8px 0 0px 0;
  height: 100%;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 24px 0 0px 0;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 3.333vw 0 0px 0;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.xl}rem) {
    padding: 3.333vw 0 0px 0;
  }
`

const SectionBreaker = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  background-repeat: no-repeat;
  background-size: cover;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.sm}rem) {
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    background-position: top;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.xl}rem) {
    background-size: 125% auto;
  }
`

const Wrapper = styled.div`
  position: relative;
`

const WrapperRight = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`

const PlatformContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 26px 0 16px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 36px 0 26px;
    align-items: start;
  }
`

const PlatformList = styled.ul`
  margin-top: 20px;
  margin-bottom: 28px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  width: 100%;
  display: none;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    display: flex;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    justify-content: flex-start;
  }
`
const PlatformListItem = styled.li`
  padding: 0 8px;
`

const ParagraphWrapper = styled.div`
  // min-height: 100px;
  margin-bottom: 12px;
  display: none;
  text-align: center;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    max-width: 70%;
    display: block;
    margin-bottom: 40px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    max-width: 100%;
    text-align: left;
  }
`
const SwiperWrapper = styled.div`
  padding-bottom: 40px;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding-bottom: 110px;
  }
  display: block;
  position: relative;
  z-index: 5;
  overflow: hidden;

  .swiper-slide {
    height: auto;
  }
`

const ThumbItem = styled.div`
  width: 100px;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  cursor: pointer;
`

type NextButtonType = {
  isLast: boolean
}

type PrevButtonType = {
  isFirst: boolean
}


const NextButton = styled.button<NextButtonType>`
  order: 3;
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #8b8682;
  opacity: 1;
  pointer-events: initial !important;

  svg {
    opacity: ${props => props.isLast ? '0.5' : '1'};

    transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1);

    &:hover {
      transform: scale(${props => props.isLast ? '1' : '1.1'});
    }
  }
`

const PrevButton = styled.button<PrevButtonType>`
  order: 1;
  background: none;
  border: none;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #8b8682;
  opacity: 1;
  pointer-events: initial !important;

  svg {
    opacity: ${props => props.isFirst ? '0.5' : '1'};
    transition: all 400ms cubic-bezier(0.19, 1, 0.22, 1);

    &:hover {
      transform: scale(${props => props.isFirst ? '1' : '1.1'});
    }
  }
`

const Number = styled.span`
  font-style: normal;
  font-weight: 900;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 8px;
  }
`

type ProgressType = {
  active: boolean
  isStop: boolean
  second: number | string
  percentage?: number | string
}

const Progress = styled.div<ProgressType>`
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    display: block;
    width: 97px;
  }
  display: none;
  width: 28px;
  height: 8px;
  border-radius: 4px;
  position: relative;
  background: ${props => props.theme.variables.primary.white};
  @keyframes progress-animation {
    0% {
      width: 0px;
    }
    100% {
      width: 100%;
    }
  }
  &:after {
    content: "";
    height: 100%;
    background: ${props => props.theme.variables.primary.black};
    border-radius: 4px;

    ${props => props.active && props.isStop && "width: 100%;"}
    ${props =>
    props.active &&
    !props.isStop &&
    `
      -webkit-animation: progress-animation ${props.second ?? 5}s linear;
      animation: progress-animation ${props.second ?? 5}s linear;
      animation-fill-mode: forwards;
    `}


    // width: ${props => props.active && props.percentage}%;
    z-index: 10;
    position: absolute;
    left: 0;
    // transition: width 1s ease-in-out;
  }
`

const ThumbWrapper = styled.div`
  --swiper-navigation-height: calc(var(--swiper-navigation-size) + 12px);
  --runner-overlap: calc(var(--swiper-navigation-height) / 2);
  position: relative;
  z-index: 1;
  margin-top: calc(0px - var(--runner-overlap));
  height: calc(var(--swiper-navigation-height) * 2.5);
  display: grid;
  grid-template-columns: 1fr min-content 1fr;
  grid-template-areas: 'left center right';

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    --swiper-navigation-height: calc(var(--swiper-navigation-size) + 24px);
  }

  .runbow {
    grid-row: 1;
    grid-column-start: left;
    grid-column-end: center;
    width: 100%;
    height: 100%;
  }
`

const ThumbSwiperWrapper = styled.div`
  grid-area: center;
  position: relative;

  &::after {
    content: '';
    display: block;
    background-image: url(${Runbow});
    width: 100vw;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    z-index: -1;
  }

  .swiper-container {
    height: var(--swiper-navigation-height);
    overflow: initial;
    display: flex;
    align-items: center;
  }

  .swiper-wrapper {
    order: 2;
    align-items: center;
    justify-content: center;
  }

  .swiper-button-prev,
  .swiper-button-next {
    cursor: pointer;
    color: ${props => props.theme.variables.secondary.base};
    opacity: 1;
    pointer-events: initial !important;

    &::after {
      font-size: 14px;
    }
  }

  .swiper-button-prev {
    order: 1;
  }

  .swiper-button-next {
    order: 3;
  }

  .swiper-slide {
    margin: 0 8px !important;
    display: flex;
    width: 28px !important;
    color: ${props => props.theme.variables.secondary.base};

    @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
      width: 100px !important;
      margin: 0 16px !important;
    }

    &.swiper-slide-thumb-active {
      color: ${props => props.theme.variables.secondary.cream};
    }
  }
`

const RunnerContainer = styled.div`
  pointer-events: none;
  grid-area: right;
  width: 100%;
  height: 100%;
  min-width: 0;
  min-height: 0;

  svg {
    position: relative;
    z-index: 10;
    height: 100%;
    opacity: 1;
  }

  :after {
    content: "";
    position: absolute;
    background: white;
    width: 100%;
    top: var(--runner-overlap);
    bottom: 0;
    left: 0;
    z-index: -1;
  }
`

const FooterColors = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
`

type FooterItemProps = {
  variable?: string
  color: string
}

const FooterItem = styled.div<FooterItemProps>`
  height: 5px;
  width: 100%;
  background-color: ${props =>
    props.theme.variables[props?.variable ?? "secondary"][props?.color]
      ? props.theme.variables[props?.variable ?? "secondary"][props?.color]
      : props.theme.variables.primary.black};
`

const Band = styled.div`
  position: relative;
  display: flex;
  height: 68px;
  background-color: ${props => props.theme.variables.secondary.yellow};
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    height: 122px;
  }
`
const StyledA = styled.a`
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
`


type HeroType = {
  items: {
    desktopBG: string
    laptopBG: string
    tabletBG: string
    mobileBG: string
    status: string
    subTitle: string
    platformTitle: string
    cta_text: string
    cta_link: string
    second_cta_link: string,
    firstButtonText: string
    secondButtonText: string
    platforms: string[]
    videoPoster?: any
    videoUrl?: string
  }[]
}

export default function HeroType2(props: HeroType & SwiperOptions) {
  const { items } = props
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass>()
  const [swiperRef, setSwiperRef] = useState<SwiperClass>()
  const [swiperIndex, setSwiperIndex] = useState<number | string | null>(null)
  const [isStop, setStop] = useState<boolean>(false)

  // const [intervalId, setIntervalId] = useState(null)

  // useEffect(() => {
  //   if (isStop) {
  //     setPercentage(100)
  //     setIntervalId(null)
  //     return
  //   }
  //   setPercentage(0)
  //   if (!intervalId) {
  //     const interval = setInterval(() => {
  //       setPercentage(oldValue => {
  //         const newValue = oldValue + 100 / second
  //         if (newValue >= 100) {
  //           clearInterval(interval)
  //           setIntervalId(null)
  //           setPercentage(100)
  //           return
  //         }
  //         return newValue
  //       })
  //     }, 1000)
  //     setIntervalId(interval)
  //   }
  // }, [swiperIndex])



  const second = 5

  return (
    <SwiperWrapper>
      <Swiper
        autoHeight={false}
        onSwiper={setSwiperRef}
        slidesPerView={1}
        thumbs={{ swiper: thumbsSwiper }}
        effect="fade"
        allowTouchMove={false}
        fadeEffect={{
          crossFade: true,
        }}
        autoplay={{
          delay: 1000 * second,
          disableOnInteraction: false,
          waitForTransition: false,
        }}
        // autoHeight={true}
        onInit={() => {
          setSwiperIndex(0)
        }}
        onSlideChange={(swiperCore: { realIndex: number | string }) => {
          const { realIndex } = swiperCore
          setSwiperIndex(realIndex)
        }}
      >
        {items &&
          !!items.length &&
          items?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
                <Section>
                  <SectionBreaker
                    className="lazyload"
                    data-bgset={`${item.desktopBG} [(min-width: 2560px)] | ${item.laptopBG} [(min-width: 1440px)] | ${item.tabletBG} [(min-width: 768px)] | ${item.mobileBG} [(min-width: 320px)] | ${item.mobileBG}`}
                  />
                  <Wrapper>
                    <Container>
                      <Row justify="space-between" reverse={["xs", "sm", "md"]}>
                        <Col xs={2} sm={2} md={8} lg={5} xl={5}>
                          <WrapperRight>
                            <HeadingWrapper>
                              <Title
                                color="white"
                                variable="primary"
                                level={1}
                                uppercase
                              >
                                {item.status}
                              </Title>
                            </HeadingWrapper>
                            <PlatformContainer>
                              <Title
                                level={3}
                                variable="secondary"
                                color="orange"
                              >
                                {item.platformTitle}
                              </Title>

                              <PlatformList>
                                {item.platforms.map(
                                  (platform: string, index) => {
                                    return (
                                      platformIconList[platform] && (
                                        <PlatformListItem key={index}>
                                          {platformIconList[platform]}
                                        </PlatformListItem>
                                      )
                                    )
                                  }
                                )}
                              </PlatformList>
                            </PlatformContainer>
                            <ParagraphWrapper>
                              <Paragraph
                                level={1}
                                variable="primary"
                                color="white"
                                weight="500"
                              >
                                {item.subTitle}
                              </Paragraph>
                            </ParagraphWrapper>
                            <ButtonWrapper>
                              {item?.firstButtonText && (
                                <Button type="primary">
                                  <StyledA href={item.cta_link} target="_blank">
                                    {item?.firstButtonText}
                                  </StyledA>
                                </Button>
                              )}
                              {item?.secondButtonText && (
                                <Button type="tertiary" isLight>
                                  <StyledA href={item.second_cta_link} target="_blank">
                                    {item?.secondButtonText}
                                  </StyledA>
                                </Button>
                              )}
                            </ButtonWrapper>
                          </WrapperRight>
                        </Col>
                        <Col xs={2} sm={2} md={8} lg={6} xl={6}>
                          <Video
                            src={item.videoUrl}
                            VideoImagePoster={item.videoPoster}
                          // autoPlay="autoPlay"
                          />
                        </Col>
                      </Row>
                    </Container>
                  </Wrapper>
                </Section>
              </SwiperSlide>
            )
          })}
      </Swiper>
      <ThumbWrapper>
        <ThumbSwiperWrapper>
          <Swiper
            onSwiper={setThumbsSwiper}
            spaceBetween={10}
            // slidesPerView={"auto"}
            slidesPerView={3}
            allowTouchMove={false}
            watchSlidesVisibility={true}
          >
            <PrevButton
              onClick={() => {
                // setSwiperIndex(swiperIndex - 1)
                swiperRef?.slidePrev()
              }}
              isFirst={swiperIndex === 0}
            >
              <Icons icon="arrow-left-circle" size={40} />
            </PrevButton>
            <NextButton
              onClick={() => {
                // setSwiperIndex(swiperIndex + 1)
                swiperRef?.slideNext()
                console.log(swiperRef)
              }}
              isLast={swiperIndex === (items?.length - 1)}
            >
              <Icons icon="arrow-right-circle" size={40} />
            </NextButton>
            {new Array(items?.length).fill({}).map((item, index) => {
              return (
                <SwiperSlide key={index}>
                  <ThumbItem
                    onClick={() => {
                      setStop(true)
                      swiperRef?.autoplay.stop()
                      swiperRef?.update()
                    }}
                  >
                    <Progress
                      active={swiperIndex === index}
                      // percentage={percentage}
                      second={second}
                      isStop={isStop}
                    />
                    <Number>
                      {index > 10 ? "" : "0"}
                      {index + 1}
                    </Number>
                  </ThumbItem>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </ThumbSwiperWrapper>
        <Runbow />
        <RunnerContainer>
          <Runner />
        </RunnerContainer>
      </ThumbWrapper>
      {/*
      <Band>
        <FooterColors>
          <FooterItem variable="secondary" color="orange" />
          <FooterItem variable="secondary" color="blue" />
          <FooterItem variable="secondary" color="pink" />
          <FooterItem variable="secondary" color="purple" />
        </FooterColors>
      </Band>
      */}
    </SwiperWrapper>
  )
}
