import React from "react"
import styled from "styled-components"
import { Col, Container, Row } from "react-awesome-styled-grid"
import Title from "../Commons/Title"
import Paragraph from "../Commons/Paragraph"
import Button from "../Commons/Button"

const ModuleWrapper = styled.div`
  position: relative;
  background-color: ${props => props.theme.variables.secondary.purple};
  margin-top: 56px;
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-top: 112px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-top: 88px;
  }
`

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    position: relative;
  }
`

const Image = styled.img`
  position: absolute;
  width: 100%;
  height: auto;
  object-fit: cover;

  max-width: 280px;
  top: -56px;
  left: 50%;
  transform: translateX(-50%);

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    max-width: 320px;
    top: -80px;
    right: 32px;
    left: auto;
    transform: initial;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    max-width: 697px;
    top: -96px;
    left: 0px;
    transform: initial;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.xl}rem) {
    max-width: 697px;
    top: -88px;
    left: 64px;
    transform: initial;
  }
`

const TitleWrapper = styled.div`
  margin-bottom: 24px;
  text-align: center;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 40px;
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    margin-bottom: 40px;
    text-align: left;
  }
`

const ParagraphWrapper = styled.div`
  text-align: center;
  margin-bottom: 48px;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    margin-bottom: 40px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    text-align: left;
    margin-bottom: 80px;
  }
`

const TextWrapper = styled.div`
  padding: 152px 0px 110px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 480px;
  margin: 0 auto;

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    padding: 189px 0px 152px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    padding: 123px 0px 185px;
    margin: 0;
    display: block;
  }
`

const RunnerWrapper = styled.div`
  position: absolute;
  bottom: calc(100% - 130px);
  z-index: 3;
  display: none;

  &:hover {
    animation: 2.4s ease-in-out 0s normal none infinite superman;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.md}rem) {
    display: block;
    max-width: 320px;
    left: 32px;
  }

  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.lg}rem) {
    max-width: 280px;
  }

  @media (min-width: 1620px) {
    max-width: 320px;
    left: calc((100vw - 76.875rem) / 4);
  }
  @media (min-width: ${props => props.theme.awesomegrid.breakpoints.xl}rem) {
    max-width: 425px;
  }
`

const RunnerImage = styled.img`
  width: 100%;
`

const HeadColors = styled.div``

type HeadItemProps = {
  variable?: string
  color: string
}

const HeadItem = styled.div<HeadItemProps>`
  height: 15px;
  background-color: ${props =>
    props.theme.variables[props?.variable ?? "secondary"][props?.color]
      ? props.theme.variables[props?.variable ?? "secondary"][props?.color]
      : props.theme.variables.primary.black};
`

const ButtonWrapper = styled.div``

const StyledA = styled.a`
  color: inherit;
  text-decoration: inherit;
  cursor: inherit;
`

type Props = {
  image: string
  runner_image: string
  title: string
  description: string
  button_text: string
}

const AboutUs: React.FC<Props> = ({
  image,
  runner_image,
  title,
  description,
  button_text,
}) => {
  return (
    <>
      <ModuleWrapper>
        <HeadColors>
          <HeadItem variable="secondary" color="yellow" />
          <HeadItem variable="secondary" color="orange" />
          <HeadItem variable="secondary" color="blue" />
          <HeadItem variable="secondary" color="pink" />
          <HeadItem variable="secondary" color="purple" />
        </HeadColors>
        <RunnerWrapper>
          <RunnerImage src={runner_image} draggable="false" />
        </RunnerWrapper>
        <Container>
          <Row>
            <Col xs={12} lg={6}>
              <TextWrapper>
                <TitleWrapper>
                  <Title level={1} variable="primary" color="white" uppercase>
                    {title}
                  </Title>
                </TitleWrapper>
                <ParagraphWrapper>
                  <Paragraph
                    level={1}
                    variable="primary"
                    color="white"
                    weight="500"
                  >
                    {description}
                  </Paragraph>
                </ParagraphWrapper>
                <ButtonWrapper>
                  <Button type="primary">
                    <StyledA href="/about">
                      {button_text}
                    </StyledA>
                  </Button>
                </ButtonWrapper>
              </TextWrapper>
            </Col>
            <Col xs={12} lg={6}>
              <ImageWrapper>
                <Image src={image} draggable="false" />
              </ImageWrapper>
            </Col>
          </Row>
        </Container>
      </ModuleWrapper>
    </>
  )
}

export default AboutUs
